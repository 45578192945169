<template>
  <div>
    <el-form>
      <el-form-item label="token">
        <el-input v-model="token"></el-input>
      </el-form-item>
      <el-form-item label="url">
        <el-input v-model="url"></el-input>
      </el-form-item>

      <el-form-item label="data">
        <el-input v-model="data"></el-input>
      </el-form-item>

      <el-form-item label="results">
        <el-input :rows="5" type="textarea" v-model="res"></el-input>
      </el-form-item>
      <el-button @click="send"> send</el-button>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      res: "",
      token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYmFja2VuZC5tYWlzb25hbHQuY29cL3YxXC90b2tlbjEiLCJpYXQiOjE2MTc4Njk0NjAsImV4cCI6MTYxNzg3MzA2MCwibmJmIjoxNjE3ODY5NDYwLCJqdGkiOiJXNmlLM1ZKVTdVY3VINE1hIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.Bkf89j5gNZUjM2Wiu06Y8W8uydTpP8uyH8oocuPVKhY",
      url: "/v1/order/list",
      data: '{"orderId":"1"}',
    };
  },

  methods: {
    send() {
      let data = {}
      if (this.data) {
        data = JSON.parse(this.data);
      }

      axios.get(this.url, data, { Authorization: `Bearer ${this.token}` }).then((res) => {
        this.res = JSON.stringify(res.data);
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
